import React from 'react'
import { Link as LinkType } from 'types'
import { SANITY_DATASET, SANITY_PROJECT_ID } from 'consts'
import cn from 'classnames'
import { A } from 'components/UtmLink'

const baseUrl = `https://cdn.sanity.io/images/${SANITY_PROJECT_ID}/${SANITY_DATASET}`

function imgUrl (assetRef: string, size = 1000) {
	return `${baseUrl}/${assetRef.slice(6, assetRef.length - 4)}.${assetRef.slice(-3)}?w=${size}&h=${size}&fit=max`
}

export const Link: React.FunctionComponent<LinkType> = ({ title, image, icon, url, color_background }) => (
		<li
			className={cn('li2', { 'li2--overlay': image })}
			style={{
				backgroundImage: image ? `url('${imgUrl(image.asset._ref)}')` : undefined,
				backgroundColor: color_background ? color_background.hex : undefined
			}}
		>
			<A href={url} className="li2__link">
				{icon && <img className="li2__icon" src={imgUrl(icon.asset._ref)} />}
				<span>{title}</span>
			</A>
		</li>
	)
