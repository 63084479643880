import LinkActions from './linkActions'
import { GetMetaActions } from 'utils'

export const Actions = {
	...LinkActions
}

export type ActionCreator = typeof Actions[keyof typeof Actions]
export type Action = ReturnType<ActionCreator>
export type MetaAction = GetMetaActions<Action>
export type ActionTypes = Action['type']
