import { Action } from 'actions'
import { LinkPage } from 'types'

type State = LinkPage & {
	redirectError: boolean
}

const initialState: State = {
	_createdAt: '',
	_updatedAt: '',
	_id: '',
	_rev: '',
	_type: '',
	title: '',
	links: [],
	redirectError: false
}

export default function (state = initialState, action: Action): State {
	switch (action.type) {
		case 'LINKS_FETCH_SUCCESSS':
			return { ...state, ...action.payload }

		case 'REDIRECT_FETCH':
			return { ...state, redirectError: false }

		case 'REDIRECT_FETCH_FAIL':
			return { ...state, redirectError: true }

		default:
			return state
	}
}
