import React from 'react'

const Footer: React.StatelessComponent = () =>  (
	<footer>
		<span>
		</span>
	</footer>
)

export default Footer
