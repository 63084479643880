/* eslint-disable prefer-template */
import React from 'react'

type Props = React.HTMLProps<HTMLAnchorElement> & {
	href: string
}

export const A: React.FunctionComponent<Props> = ({ href, ...props }) => {
	const url = new URL(href)
	const content = url.pathname.split('/').pop()
	return <a {...props} href={
		href +
		(href.includes('?') ? '&' : '?') +
		'utm_source=ig&utm_medium=bio-links' +
		(content ? `&utm_content=${content}` : '')
	} />
}
