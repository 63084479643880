import { Actions } from 'actions'
import { call, put, takeLatest  } from 'redux-saga/effects'
import { SANITY_DATASET, SANITY_PAGE_ID, SANITY_PROJECT_ID, SANITY_TOKEN } from 'consts'
import { LinkPage } from 'types'
import { AnyAction } from 'redux'

export default function* () {
	yield takeLatest(Actions.linksFetch.type, fetchLinks)
	yield takeLatest(Actions.redirectFetch.type, fetchRedirect)
}

type Result<T> = {
	result: T
}

const baseUrl = `https://${SANITY_PROJECT_ID}.apicdn.sanity.io/v1/data/query/${SANITY_DATASET}`

const headers = new Headers({ Autorization: `Bearer ${SANITY_TOKEN}`})

function* fetchLinks () {
	const response: Response = yield call(fetch, `${baseUrl}?query=*[ _id=="${SANITY_PAGE_ID}"]`, { headers })
	const body = yield response.json()
	yield put(Actions.linksFetchSuccess(body.result[0]))
}

function* fetchRedirect (action: AnyAction) {
	const path: string = action.payload
	const response: Response = yield call(fetch, `${baseUrl}?query=*[ title=="Redirects"]`, { headers })
	const body: Result<LinkPage[]> = yield response.json()
	const link = body.result[0]?.links.find(ln => ln.title.toLocaleLowerCase() == path.toLocaleLowerCase())
	if (link && link._type == 'link') {
		const mobileUrl = window.innerWidth < 768 ? link.mobileUrl : null
		const target = new URL(mobileUrl || link.url)
		const defaults: Record<string, string> = {
			utm_source: 'auk',
			utm_medium:'go',
			utm_term: path
		}
		for (const key in defaults) {
			if (!target.searchParams.has(key))
				target.searchParams.set(key, defaults[key])
		}
		const windowParams = new URLSearchParams(window.location.search)
		for (const key of windowParams.keys()) {
			const value = windowParams.get(key) as string
			if (!target.searchParams.has(key))
				target.searchParams.set(key, value)
		}

		window.open(target.toString(), '_self')
	}
	else {
		yield put(Actions.redirectFetchFail())
	}
}
