import { Actions } from 'actions'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { State } from 'types'
import loadingIcon from 'static/loading.gif'

import './Redirect.less'

type Props = RouteComponentProps<any> & ReturnType<typeof mapStateToProps> & typeof dispatchToProps

const Redirect: React.FunctionComponent<Props> = ({ location, redirectFetch, error }) => {
	useEffect(() => {
		redirectFetch(location.pathname.replace('/', ''))
	}, [location.pathname])

	return (
		<main className="redirect">
			<img className="redirect__img" src={loadingIcon} alt="Redirecting..." />
			{error && (
				<>
					<h1>
						Failed to redirect to <em>{location.pathname}</em>. Is the URL correct?
					</h1>
					<p>
						Go back to{' '}
						<a href="https://www.auk.eco" target="_self">
							auk.eco
						</a>
						, or contact <a href="https://support.auk.eco">support</a> if you think this is a mistake.
					</p>
				</>
			)}
		</main>
	)
}

const mapStateToProps = (state: State) => ({
	error: state.links.redirectError
})

const dispatchToProps = {
	redirectFetch: Actions.redirectFetch
}

export default connect(mapStateToProps, dispatchToProps)(Redirect)
