import React from 'react'
import { A } from 'components/UtmLink'
import aukLogo from 'static/auk-logo.svg'

import './navbar.less'

const Navbar: React.StatelessComponent = () => (
		<header>
			<nav>
				<A href="https://www.auk.eco/">
					<img src={aukLogo}  />
				</A>
			</nav>
		</header>
	)
export default Navbar
