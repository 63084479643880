import { Home, Redirect } from 'components/Routes'
import React from 'react'
import { hot } from 'react-hot-loader'
import { Route, Switch } from 'react-router'

import './App.less'

const App: React.StatelessComponent = () => (
	<Switch>
		<Route exact path="/" component={Home} />
		<Route component={Redirect} />
	</Switch>
)

export default hot(module)(App)
