import { ConnectedRouter, replace } from 'connected-react-router'
import RedBox from 'redbox-react'
import React from 'react'
import { Provider } from 'react-redux'
import configureStore, { history } from '../../configureStore'
import App from './App'
import { snakeToCamel } from 'utils'

console.info(snakeToCamel(process.env.PACKAGE_NAME as string), process.env.PACKAGE_VERSION)

const store = configureStore()

if (location.hostname.split('.').length == 3) {
	const path = location.hostname.split('.')[0]
	if (!['links', 'go'].includes(path))
		store.dispatch(replace(path))
}

type State = {
	error: Error | null
}

class Root extends React.Component<{}, State> {
	state: State = {
		error: null
	}

	static getDerivedStateFromError (error: Error) {
		return { error }
	}

	componentDidCatch (error: Error, info: React.ErrorInfo) {
		// tslint:disable-next-line:no-console
		console.warn(info)
	}

	render () {
		const { error } = this.state
		if (error && process.env.NODE_ENV !== 'test') {
			return <RedBox error={error} />
		}
		return (
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</Provider>
		)
	}
}

export default Root
