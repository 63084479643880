import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import links from './links'

const reducers = (history: History) => combineReducers({
	links,
	router: connectRouter(history)
})

export default reducers
