import { Actions } from 'actions'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { State } from 'types'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import { Link } from './Link'

import './home.less'
type Props = ReturnType<typeof mapStateToProps> & typeof dispatchToProps

const Home: React.StatelessComponent<Props> = ({ page, fetchLinks }) => {
	useEffect(() => {
		fetchLinks()
	}, [])

	return page == null ? (
		<div />
	) : (
		<>
			<Navbar />
			<main>
				<div className="home">
					<ul>
						{page.links.map(link => {
							switch (link._type) {
								case 'link-header':
									return <h1 key={link._key}>{link.title}</h1>
								case 'link':
									return <Link key={link._key} {...link} />
								default:
									return <></>
							}
						})}
					</ul>
				</div>
			</main>
			<Footer />
		</>
	)
}

const mapStateToProps = (state: State) => ({
	page: state.links
})

const dispatchToProps = {
	fetchLinks: Actions.linksFetch
}

export default connect(mapStateToProps, dispatchToProps)(Home)
